import { inRange } from 'lodash';

export const getGoalBasedOnMasteryLevel = (currentMasteryLevel: number): number => {
  if (inRange(currentMasteryLevel, 0.0, 1.0)) {
    return 1;
  }
  if (inRange(currentMasteryLevel, 1.0, 2.0)) {
    return 2;
  }
  if (inRange(currentMasteryLevel, 2.0, 3.0)) {
    return 3;
  }
  return 1000;
};
